<template>
  <v-app id="investor">
    <v-dialog width="250px" v-model="qrDialog" persistent>
      <v-card>
        <v-card-title class="text-h6 headline">
          C&oacute;digo QR
        </v-card-title>
        <v-card-text>
          <div id="div-qr-user" v-html="qrCode">
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="downloadSVG">
            Descargar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="qrDialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <VerticalSidebar :expand-on-hover.sync="expandOnHover"></VerticalSidebar>
    <v-app-bar app elevation="0" color="white" class="headerpart">
      <v-app-bar-nav-icon @click="Sidebar_drawer = !Sidebar_drawer">
        <v-icon v-if="!Sidebar_drawer">
          mdi-arrow-right
        </v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar flat class="align-center headerpart">
        <v-responsive max-width="300" height="45" class="hidden-sm-and-down" v-if="false">
          <v-text-field id="findtext" label="Buscar.." name="find" outlined dense single-line clearable
            background-color="white" hide-details class="white--text mt-0 pt-0 mr-2" prepend-inner-icon="mdi-magnify">
          </v-text-field>
        </v-responsive>
        <v-spacer></v-spacer>
        <v-menu left bottom transition="slide-y-reverse-transition" class="hidden-md-and-up" offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" color="black" v-bind="attrs" :disabled="cantMessage===0">
              <v-badge color="#ded06f" :content="cantMessage" :value="cantMessage" overlap>
                <v-icon color="#0000008a">
                  mdi-bell-outline
                </v-icon>
              </v-badge>
            </v-btn>
          </template>

          <v-list light color="darkbarcolor" dense>
            <v-list-item>
              <h4 class="px-4 py-2 pt-1 font-weight-medium title">Notificaciones</h4>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="text-caption text-xl-h5 font-weight-regular" link @click="actionItemNotifications"
              v-for="(item, i) in messages.slice(0, 5)" :key="i">
              <v-list-item-action>
                <v-list-item-title>
                  <div class="d-flex align-center">
                    <div class>
                      <v-btn class="mr-3" depressed fab small dark color="#ded06f">
                        <v-icon dark>mdi-bell</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <h4 class="font-weight-medium">{{ item.title }}</h4>
                      <span class="text--secondary caption d-block text-truncate">{{ item.message }}</span>
                      <small class="text--secondary">{{ item.created_at | toCurrency }}</small>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="messages.length > 5"></v-divider>
            <v-list-item v-if="messages.length > 5">
              <RouterLink class="ml-auto mr-auto" to="/investor-notifications">Ver m&aacute;s notificaciones
              </RouterLink>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu transition="slide-y-reverse-transition" class="hidden-md-and-up" offset-y open-on-hover>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" color="black" v-bind="attrs">
              <v-avatar v-if="user" color="#ded06f" size="32">
                <v-img
                  :src="(user.pictures !== null && user.pictures && user.pictures.length > 0) ? user.pictures[0].url : require('@/assets/images/no-image.jpg')"></v-img>
              </v-avatar>
              <v-icon large color="#C7BA65FF" v-else>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list light color="darkbarcolor" dense>
            <template>
              <v-list-item>
                <v-avatar v-if="getStatusLogin && user" color="#ded06f" size="32">
                  <v-img
                    :src="(user.pictures !== null && user.pictures && user.pictures.length > 0) ? user.pictures[0].url : require('@/assets/images/no-image.jpg')"></v-img>
                </v-avatar>
                <v-icon large color="#C7BA65FF" v-else>mdi-account</v-icon>
                <div class="align-center ml-1">
                  <div class="pt-1">
                    <h5 class="font-weight-regular" style="font-size: 12px">{{ user?.name }} {{user?.last_name}}</h5>
                    <span class="subtitle-2 d-block font-weight-regular"
                      style="font-size: 10px !important;">{{user?.email}}</span>
                  </div>
                </div>
              </v-list-item>
              <v-divider></v-divider>
              <!--                            <v-list-item
                                link
                                class="text-caption text-xl-h5 font-weight-regular">
                                <v-list-item-action>
                                    <v-list-item-title class="text-caption text-xl-h6 text-uppercase">
                                        Mi Perfil
                                    </v-list-item-title>
                                </v-list-item-action>
                            </v-list-item-->
              <v-list-item link class="text-caption text-xl-h5 font-weight-regular">
                <v-list-item-action>
                  <v-list-item-title class="text-caption text-xl-h6 text-uppercase">
                    Cambiar Contrase&ntilde;a
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item @click="qrDialog=true" link class="text-caption text-xl-h5 font-weight-regular">
                <v-list-item-action>
                  <v-list-item-title class="text-caption text-xl-h6 text-uppercase">
                    C&oacute;digo QR
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item link @click="doLogout" class="text-caption text-xl-h5 font-weight-regular">
                <v-list-item-action>
                  <v-list-item-title class="text-caption text-xl-h6 text-uppercase">
                    Cerrar Sesi&oacute;n
                  </v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-app-bar>

    <v-main>
      <v-container class="container-fluid py-2 px-2" fluid>
        <transition name="router-anim" enter-active-class="fade-enter-active fade-enter"
          leave-active-class="fade-leave-active fade-enter">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>

    <v-footer app class="footerpart" inset>
      <v-container class="py-0 my-0">
        <v-col cols="12">
          <span class="d-block text-center caption black--text lighten-2">
            © {{ new Date().getFullYear() }} — Todos los derechos reservados PEB.
            Desarrollado por <a href="https://www.idoogruop.com/" class="black--text font-weight-bold">IdooGroup</a>
          </span>
        </v-col>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
    import VerticalSidebar from "./vertical-sidebar/VerticalSidebar";
    import { mapState, mapMutations } from "vuex";
    import loginService from '@/providers/LoginService';
    import userService from '@/providers/UsersService';
    import Pusher from "pusher-js";

    export default {
        name: "InvestorLayout",
        components: {
            VerticalSidebar
        },
        props: {
            source: String,
        },
        data: () => ({
            drawer: null,
            user: {},
            investor: null,
            expandOnHover: false,
            qrCode: "",
            qrDialog: false,
            loadingSave: false,
            dialogPassword: false,
            password: "",
            valid: false,
            rules_password: [
              v => !!v || 'El campo es requerido',
              v => !v.length > 7 || 'La contraseña debe tener 8 o más dígitos'
            ],
            rules_repeat_password: [
              () => this.password === this.repeat_password || 'Las contraseñas no coinciden'
            ],
            repeat_password: "",
            pusher:  new Pusher('13e4db1337e9189ec1aa', {
              cluster: 'us2'
            }),
            channel: null,
        }),
        filters:{
          toCurrency(value){
            return (new Date(value)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
          },
        },
        computed: {
            ...mapState(["Customizer_drawer", "setHorizontalLayout"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                }
            },
            cantMessage(){
              return this.$store.state.notifications.length;
            },
            messages(){
              return this.$store.state.notifications;
            },
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },
        methods: {
            downloadSVG() {
              const div = document.getElementById("div-qr-user");
              const svgContent = div.childNodes[2];

              const uriData = `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(svgContent))}`
              const img = new Image();
              img.src = uriData;

              img.onload = () => {
                const canvas = document.createElement("canvas");
                [canvas.width, canvas.height] = [200, 200];
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, 200, 200);
                const a = document.createElement("a");
                const quality = 1.0;
                a.href = canvas.toDataURL("image/png", quality)
                a.download = `${this.user.name} ${this.user.last_name} 2fa.png`;
                a.append(canvas)
                a.click()
                a.remove()
              }
            },
            ...mapMutations({
                setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
            }),
            closePassword() {
                this.password = "";
                this.repeat_password = "";
                this.dialogPassword = false;
            },
            doLogout() {
                loginService.logoutUser(true);
            },
            actionItemNotifications(){
              this.$router.push({path: "/investor-notifications"}).catch(err=>err);
            },
            changePasswordAction(){
              this.loadingSave = true;
              userService.changePassword(this.user.id, this.password).then((resp)=>{
                if(resp.success){
                  this.password = "";
                  this.repeat_password = "";
                  this.dialogPassword = false;
                }
              }).catch(err=>console.log(err)).finally(()=>{
                this.loadingSave = false;
              })
            },
        },
        beforeMount() {
            this.user = this.$store.getters.getUser;
            userService.getUserCurrent().then(resp=>{
                this.$store.state.user = {...resp};
                this.user = {...resp};
            }).catch(err=>console.log(err));
        },
        mounted() {
            this.investor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            this.$store.state.notifications = [];
            userService.getNotificationInvestorNotRead().then(resp=>{
              if(resp.success){
                this.$store.state.notifications = resp.value.map(e=>e.notification);
              }
            }).catch(err=>console.log(err));

            userService.getQRCodeURL().then(resp => {
              if (resp.success) {
                this.qrCode = resp.value;
              }
            }).catch(err => console.log(err));

            if (this.investor !== null) {
                let isLogin = this.investor.isLogin;
                if (isLogin) {
                    let investor_id = this.user.investors[0].id;
                    this.$router.push({
                        name: "InvestorDashboard",
                        params: {
                            id: investor_id,
                        }
                    }).catch(err=>err);
                } else {
                    this.$router.push({path: "/homepage"}).catch(err=>err);
                }
            } else {
                this.$router.push({path: "/homepage"}).catch(err=>err);
            }

          this.channel = this.pusher.subscribe("new-notifications");
          this.channel.bind(this.user.email, (data)=> {
            this.$store.state.notifications = [
              data,
              ...this.$store.state.notifications
            ];
            Notification.requestPermission().then((result) => {
              if(result==="granted"){
                new Notification(data.title, {
                  body: data.message,
                  icon: 'https://private.uat.idooproject.com//img/logo-private-black-transparent.93c30aa8.png',
                });
              }
            });
          });
        }
    }
</script>

<style lang="scss">
    .v-main {
        /*background-color: #F9FAFC;*/
        background-color: #F7F7F7;
    }
    .v-application .footerpart {
        border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    .v-application .headerpart {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    .theme--light.v-footer {
        background: white;
        padding: 0;
        margin: 0;
    }
</style>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }

    .item-notification:hover{
      background-color: #e1e0e0;
      transition: all 0.1s ease-in-out;
    }
</style>