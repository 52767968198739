import { render, staticRenderFns } from "./InvestorLayout.vue?vue&type=template&id=4c56754c&scoped=true"
import script from "./InvestorLayout.vue?vue&type=script&lang=js"
export * from "./InvestorLayout.vue?vue&type=script&lang=js"
import style0 from "./InvestorLayout.vue?vue&type=style&index=0&id=4c56754c&prod&lang=scss"
import style1 from "./InvestorLayout.vue?vue&type=style&index=1&id=4c56754c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c56754c",
  null
  
)

export default component.exports