import { render, staticRenderFns } from "./VerticalSidebar.vue?vue&type=template&id=cae298b6"
import script from "./VerticalSidebar.vue?vue&type=script&lang=js"
export * from "./VerticalSidebar.vue?vue&type=script&lang=js"
import style0 from "./VerticalSidebar.vue?vue&type=style&index=0&id=cae298b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports