export default [
    {
        icon: 'mdi-account-multiple-outline',
        title: 'Administración',
        link: null,
        active: true,
        grouped: true,
        children: [
            {title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/investor-dashboard', active: true},
            {title: 'Proyectos', icon: 'mdi-codepen', link: '/investor-projects', active: true},
            {title: "Eventos", icon: 'mdi-view-carousel', link: "/investor-events/0", active: true},
        ]
    }
];