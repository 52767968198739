<template>
    <v-navigation-drawer
            v-model="Sidebar_drawer"
            :dark="SidebarColor !== 'white'"
            :color="SidebarColor"
            mobile-breakpoint="960"
            mini-variant-width="70"
            :expand-on-hover="expandOnHover"
            app
            id="investor-sidebar">
        <!---User Area -->
        <v-list-item two-line class="profile-bg">
            <v-img
                src="@/assets/images/logo-private-transparent.png"
                height="65"
                contain/>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense expand>
            <v-list-item
                v-for="(item, idx) in admins"
                :key="idx"
                :to="item.link"
                link>
                <v-list-item-title class="orange--text text--lighten-2" v-text="item.title"></v-list-item-title>
                <v-list-item-icon>
                    <v-icon color="primary" v-text="item.icon"></v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import { mapState } from "vuex";
    import VerticalSidebarItems from "./VerticalSidebarItems";

    export default {
        name: "VerticalSidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            items: VerticalSidebarItems,
            user: null,
            admins: [
                {title: 'Dashboard', icon: 'mdi-chart-areaspline', link: '/investor-dashboard', active: true, grouped: false},
                {title: 'Proyectos', icon: 'mdi-codepen', link: '/investor-projects', active: true, grouped: false},
                {title: 'Eventos', icon: 'mdi-view-carousel', link: '/investor-events/0', active: true, grouped: false},
                {title: 'Buzón', icon: 'mdi-bell-circle', link: '/investor-notifications', active: true, grouped: false},
            ]
        }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                },
            },
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },
        watch: {
            /*"$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            },*/
        },
        methods: { },
        mounted() {
            this.user = this.$store.getters.getUser;
        }
    };
</script>

7<style lang="scss">
    #investor-sidebar {
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        
        .v-navigation-drawer__border {
            display: none;
        }
        .v-list {
            padding: 2px 2px;
        }
        .v-list-item {
            min-height: 35px;
            &__icon--text,
            &__icon:first-child {
                justify-content: center;
                text-align: center;
                width: 20px;
            }
        }
        .v-list-item__icon i {
            width: 20px;
        }
        .icon-size .v-list-group__items i {
            width: 16px;
            font-size: 16px;
        }
        .profile-bg {
            &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
                opacity: 1;
            }
            .v-avatar {
                padding: 15px 0;
            }
        }
        .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
</style>